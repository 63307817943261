.navbar {
  // background-color: #1c054c;
  // background-color: transparent;
  background: linear-gradient(90deg, #7433BF 1.55%, #4B67EC 95.4%);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding-right: 5vw;
  padding-left: 5vw;
  padding-top: 5px;
}

.navbar-logo {
  margin-right: auto;
  display: flex;
  align-items: center;
}




.navbar-logo img {
  max-width: 180px;
  display:none;
}

.navbar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  font-family: "Avenir-Bold";
  gap: 3vw;
}
.downloadimage {
  width: 8rem;
}
.navbar-item {
  margin: 0 10px;
}

.navbar-item a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

.navbar-item a:hover {
  color: #fd1dba;
}

.navbar-item a button {
  background-color: #b700b3;
  color: #ffffff;
  border-radius: 50px;
  padding: 18px;
  font-weight: bold;
  border: none;
  font-family: Avenir-bold;
  letter-spacing: 0.5px;
}

.navbar-item a button:hover {
  background-color: #fd1dba;
}

/* Mobile view */

@media (max-width: 768px) {
  .navbar-list {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #1c054c;
    text-align: center;
  }

  .navbar-list.show {
    display: flex;
    flex-direction: column;
  }

  .navbar-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 5px;
  }

  .navbar-toggle span {
    width: 30px;
    height: 4px;
    background-color: #ffffff;
    margin: 2px 0;
    border-radius: 2px;
  }
  .navbar-toggle:hover span {
    background-color: #fd1dba;
  }

  .navbar-toggle.open span:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggle.open span:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }

  .navbar-toggle.open span:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }

  /* Mobile view - show menu */
  .navbar-list {
    display: none;
    position: absolute;
    top: 46px;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, #A880EB 0%, #9485F8 100%);
    text-align: center;
    z-index: 998;
  }

  .navbar-list.show {
    display: flex;
    flex-direction: column;
  }

  .navbar-item {
    margin: 10px 0;
  }

  .navbar-item a {
    display: block;
    padding: 10px 0;
    font-size: 18px;
  }
}
@media (min-width: 769px) {
  .navbar-toggle {
    display: none;
  }
  .downloadimage img {
    width: 18vw;
  }
}
