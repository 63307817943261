
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 14.5vw;
  flex-wrap: wrap;
  font-family: "Avenir-Regular";
  padding-bottom: 4rem;
}

.featureheading {
  text-align: left;
  font-size: 2.4rem;
  color: #2c2c2c;
  font-family: "Avenir-Bold";
  padding-left: 13vw;
  padding-right: 13vw;
  letter-spacing: 0.02em;
  font-weight: 700;
  max-width: 1200px;
  margin: 0 auto 0 auto;
  padding-bottom: 32px;
}

.card {
  width: 100%;
  flex-basis: calc(50% - 20px);
  height: 210px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #367BF6 0%, #9D1FE1 100%);
  box-sizing: border-box;
}
.card2 {
    width: 100%;
    flex-basis: calc(50% - 20px);
    height: 210px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, #9428E4 0%, #D33B78 100%);
    box-sizing: border-box;
  }
  

.card__image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__image img {
  width: 25vw;
  max-width: 150px;
  padding-left: 30px;
}

.card__content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.underLine {
  border: 1px solid #420b71;
  background-color: #420b71;
  padding: 0 5vw;
  font-size: 1px;
  margin-left: 5vw;
}

.card__content p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #f5f5f5;
  font-family: "Avenir-regular";
  font-weight: 500;
}

.featureheading span {
  border-bottom: 4px solid #420b71;
  width: 85px;
  display: inline-block;
}

/* Media queries */
@media screen and (max-width: 1000px) {
  .card {
    flex-basis: 90%;
  }
  .card2 {
    flex-basis: 90%;
  }
  .card__image img {
    width: 60vw;
    max-width: 200px;
    padding-left: 35px;
    padding-right: 20px;
  }
  .cards{
    padding: 0 9vw;
  }
}
@media screen and (max-width: 1517px) {
    
    .card__image img {
      width: 70vw;
      max-width: 200px;
      padding-left: 15px;
    }
    .cards{
      padding: 0 12vw;
      padding-bottom: 4rem;
    }
    .card__content p{
        font-size: 16px;
        padding-right: 10px;
    }
  }
@media screen and (max-width: 768px) {
  .featureheading {
    padding-right: 2vw;
    font-size: 1.6rem;
  }

  .card__image img {
    width: 50vw;
    max-width: 200px;
    padding-left: 10px;
  }

  .card__content {
    width: 100%;
  }
  .card__content p {
    font-size: 16px;
    padding-right: 10px;
  }

  .underLine {
    margin-left: 2vw;
    padding: 0 2vw;
  }
  .card {
    flex-basis: 90%;
  }
  .card2 {
    flex-basis: 90%;
  }
}

@media screen and (max-width: 550px) {
 
.cards{
    padding-bottom: 3.5rem;
}
  .card {
    flex-basis: 100%;
    height: auto;
    margin: 10px 0;
  }
  .card2 {
    flex-basis: 100%;
    height: auto;
    margin: 10px 0;
  }

  .card__image {
    width: 40vw;
    padding-left: 20px;
  }

  .card__image img {
    width: 100%;
    max-width: 150px;
  }

  .card__content {
    width: 50%;
  }
  .card__content p {
    font-size: 14px;
    padding-right: 10px;
}
  .underLine {
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0;
  }
}
