.footer {
  color: white;
  font-family: "Avenir-regular";

  background: linear-gradient(90deg, #7433bf 1.55%, #4b67ec 95.4%);
}

.footer-logo {
  width: 20vh;
  margin: 3vw;
  margin-bottom: 0;
}

h4 {
  color: white;
  padding: 3vw;
  padding-top: 0;
  padding-bottom: 0;
  margin-block-end: -0.5em;
  font-size: 1.2rem;
}

.policyy {
  color: white;
  padding-top: 0;
  padding-bottom: 0;
  margin-block-end: -0.5em;
  font-size: 1.2rem;
  padding-right: 2rem;
}
.footer-email {
  padding-top: 0;
}
a.footer-privacy-policy {
  padding-top: 15px;
}

.footer-address {
  color: white;
  padding: 3vw;
  padding-top: 0;
}
.footer-copyright p {
  background-color: #ffffff;
  color: #000000;
  padding: 10px;
  padding-top: 1rem;
  text-align: center;
  font-size: 0.8rem;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.footer-section1 {
  grid-column: 1 / span 1;
}

.footer-section2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-section2-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-section3 {
  grid-column: 3 / span 1;
}

.footer-section-heading {
  padding-top: 2vw;
  padding-left: 0;
  text-align: left;
}

.footer-privacy-policy {
  color: white;
  text-decoration: none;
}

.footer-section3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-connect {
  margin-bottom: 1rem;
}

.footer-connect,
.footer-email,
.footer-privacy-policy {
  font-size: 1rem;
  text-align: center;
}
a {
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .footer-grid {
    display: flex;
    flex-direction: column;
  }

  .footer-section1 {
    order: 1;
    text-align: center;
  }

  .footer-section2 {
    order: 2;
  }

  .footer-section3 {
    order: 3;
  }
  .footer-section2-flex {
    align-items: center;
  }
  .footer-logo {
    width: 40vw;
    margin: 0 auto;
    margin-top: 2rem;
  }

  h4 {
    font-size: 1.2rem;
    text-align: center;
  }
  .footer-section-heading {
    text-align: center;
  }
  .footer-address,
  .footer-email,
  .footer-privacy-policy {
    font-size: 0.9rem;
    text-decoration: none;
  }
}
.footer-social-icons img {
  width: 14px;
  padding-right: 10px;
}
.codezyng {
  text-decoration: none;
  color: black;
}
.policyy h4 {
  color: white;
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-block-end: -0.5em;
  font-size: 1.2rem;
}
.footer-social-icons{
  padding-top: 1rem;
}
.footer-social-icons .mailicon{
  width:16px;
}
@media screen and (max-width: 768px) {
  h4.policyy {
    text-align: center;
    padding: 0;
  }
}
