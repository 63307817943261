@font-face {
  font-family: Avenir-Bold;
  src: url(../../Assets/Fonts/AvenirLTStd-Black.otf);
}
@font-face {
  font-family: Avenir-Regular;
  src: url(../../Assets/Fonts/AvenirLTStd-Roman.otf);
}
@font-face {
  font-family: Avenir-Light;
  src: url(../../Assets/Fonts/AvenirLTStd-Book.otf);
}


.card-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 3vw;
}

.cardUser {
  display: inline-block;
  width: 440px;
  height: 410px;
  margin: 10px;
  border-radius: 15px;
  text-align: center;
  background-color: #ffffff;
  position: relative;
  box-shadow: 2px 2px 50px 4px rgba(0, 0, 0, 0.06);

}

.cardUser .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  bottom: -100%;
  transition: bottom 0.3s ease;
}

.cardUser:hover .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0%;
}

.cardUser h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.cardUser img {
  border-radius: 5px;
  margin-bottom: 10px;
  width: 20rem;
}

.cardUser:hover img {
  opacity: 0;
}

.cardUser .overlay h3 {
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
}

.usersheading {
  text-align: left;
  font-size: 2.4rem;
  color: #2c2c2c;
  font-family: "Avenir-Bold";
  padding-left: 13vw;
  padding-right: 13vw;
  letter-spacing: 0.02em;
  font-weight: 700;
  max-width: 1300px;
  margin: 0 auto 0 auto;
  padding-top: 3vw;
}

.button-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.usersheading span {
  border-bottom: 4px solid #420b71;
  display: inline-block;
  padding-bottom: 5px;
  margin-right: 90px;
  width: 95px;
}

.button-container button {
  padding: 5px 50px;
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(180deg, #972abe 0%, #4a0e75 100%);
  border: none;
  color: #fff;
  font-size: 18px;
  font-family: "Avenir-Bold";
  cursor: pointer;
}
.cardUser .discription {
  display: none;
  position: absolute;
  opacity: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 10px;
  height: 100%;
  bottom: 0;
  left: 0;
  transition: 0.9s;
}
.cardUser .cuntent {
  position: absolute;
  left: 20%;
  width: 60%;
  height: 60px;
  bottom: 0;
  background: linear-gradient(180deg, #972ABE 0%, #CF3A81 100%);
  transition: 0.5s;
  overflow: hidden;
  padding: 2px 2px;
  border-radius: 10px 10px 0 0;
  margin: 0;
}
.cardUser:hover .feature1 {
  color: white;
  margin-top: 46px;
  padding-top: 5px;
}
.cardUser .cuntent .feature1 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding-top: 0;
  font-family: "Avenir-regular";
}
.cardUser .cuntent .discription {
  display: flex;
  font-size: 18px;
  padding: 0px 40px;
  opacity: 0;
  transition: 0.5s;
  color: #fff;
  text-align: left;
  font-family: "Avenir-regular";
}
.cardUser:hover .cuntent {
  background: linear-gradient(180deg, #972ABE 0%, #CF3A81 100%);
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 15px;
}

.cardUser:hover .cuntent .discription {
  color: #fff;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
  line-height: 1.4rem;
  opacity: 1;
  transition-delay: 0.5s;
}

.cardUser .discription p {
  padding: 1rem;
}
@media screen and (max-width: 767px) {
  .cardUser .cuntent .discription {
    font-size: 18px;
    padding-right: 30px;
  }
  .cardUser .cuntent .feature1 {
    font-size: 20px;
  }
  .usersheading{
    font-size: 1.6rem;

  }
  .usersheading span{
    margin-right: 30px;
  }
}
@media screen and (max-width: 467px) {
  .cardUser .cuntent .discription {
    font-size: 16px;
    padding-right: 30px;
  }
  .cardUser .cuntent .feature1 {
    font-size: 18px;
  }
}
