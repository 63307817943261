.about {
  font-family: "sans-serif";
  border-radius: 100px;

  .aboutheading {
    text-align: left;
    font-size: 2.4rem;
    color: #2c2c2c;
    padding-top: 5vw;
    max-width: 1200px;
    margin: 0 auto 0 auto;
    font-family: "Avenir-Bold";
    letter-spacing: 0.02em;
    font-weight: 600;
    padding-left: 13vw;
    padding-right: 13vw;
  }

  .aboutheading span {
    border-bottom: 4px solid #420b71;
    width: 85px;
    display: inline-block;
  }

  .aboutpara {
    color: #2c2c2c;
    text-align: left;
    max-width: 1200px; /* set the desired max-width */
    margin: 0 auto 0 auto;
    font-size: 1.1em;
    line-height: 1.4em;
    padding-bottom: 4vw;
    font-family: "Avenir-Regular";
    padding-left: 13vw;
    padding-right: 13vw;
    padding-bottom: 6rem;
    padding-top: 2rem;
  }

  .curve1 {
    position: absolute;
    left: 0;
    top: 4.5vh;
    width: 70px;
  }

  .curve2 {
    position: absolute;
    left: 0;
    top: 3.5vh;
    width: 90px;
  }

  @media (max-width: 747px) {
    .curve1 {
      display: none;
    }
    .curve2 {
      display: none;
    }
    .aboutpara {
      font-size: 14px !important;
      padding-bottom: 4rem;
    }
    .aboutheading {
      font-size: 1.6rem;
    }
  }
}
