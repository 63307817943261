
.zupaloop {
  /* background-image: url("../../Assets/images/image 1295.png"); */
  /* background:linear-gradient(180deg, #992ADC 0%, #C63890 100%); */
  height: 75vh;
  background-image: url("../../Assets/images/Group\ 167.png");
  z-index: 1;
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  transition: all .1s;
  background-size: 100% 100%;  
  /* animation: backgroundMove 5s linear infinite; animate the background */
  /* animation: gradientBgAnimate 8s ease infinite; */
}

@keyframes gradientBgAnimate {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.description{
  font-size: 1.4rem;
}

.pro {
  display: flex;
  padding-bottom: 8vw;
  flex-wrap: wrap;
  padding-top: 15vh;
}
.image-left {
  width: 40vw;
  position: relative;
  padding-left: 20vw;
}
.image-right {
  width: 40vh;
  position: relative;
}

p {
  padding-top: 1vw;

  color: white;
}
.button {
  background-color: white;
  border: 2px solid black;
  color: black;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  width: 180px;
}
.icon {
  height: 25px;
  background-color: white;
  margin-right: 10px;
}
.brand-logo {
  width: 90px;
  
}
.details {
  margin-left: 10vw;
  margin-top: 5vw;
}
.text-small {
  font-size: 0.7rem;
  padding-right: 3.5em;
}
.text-large {
  font-size: 1rem;
  font-weight: bold;
  padding-right: 1.1em;
}
.logo {
  width: 15rem;
}
.powered-by {
  font-size: 0.8rem;
}
.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}
.appstore{
  width: 20vh;
  border-radius: 10px;
}
@media only screen and (max-width: 1000px) {
  
  .image-right {
    display: none;
  }
  .image-left{
    display: none;
  }
.zupaloop{
  background-image: none;
  background: linear-gradient(180deg, #762FBC 0%, #4370F4 100%);;
}
  .mobile-only {
    display: none;
    width: 40vh;
    padding-left: 3rem;
  }
  .details{
    margin-left: 20vw;
  }
}

